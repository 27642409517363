import { api } from "../utils/request";

async function getGroupedStats(
	groupBy,
	stat,
	_id,
	ref,
	active,
	role,
	offices,
	commercialDirectors,
	teams,
	billingMin,
	billingMax,
	commissionsMin,
	commissionsMax,
) {
	let query = "";

	if (_id) query += `${query ? "&" : "?"}_id=${_id}`;
	if (ref) query += `${query ? "&" : "?"}ref=${ref}`;
	if (active) query += `${query ? "&" : "?"}active=${active}`;
	if (role) query += `${query ? "&" : "?"}role=${role}`;
	if (offices) {
		for (const office of offices) {
			query += `${query ? "&" : "?"}officesFilter=${office}`;
		}
	}
	if (commercialDirectors) {
		for (const commercialDirector of commercialDirectors) {
			query += `${query ? "&" : "?"}commercialDirectors=${commercialDirector._id}`;
		}
	}
	if (teams) {
		for (const team of teams) {
			query += `${query ? "&" : "?"}teams=${team}`;
		}
	}
	if (billingMin) query += `${query ? "&" : "?"}billingMin=${billingMin}`;
	if (billingMax) query += `${query ? "&" : "?"}billingMax=${billingMax}`;
	if (commissionsMin) query += `${query ? "&" : "?"}commissionsMin=${commissionsMin}`;
	if (commissionsMax) query += `${query ? "&" : "?"}commissionsMax=${commissionsMax}`;

	const res = await api({
		method: "get",
		url: `/api/stats/${groupBy}/${stat}${query}`,
	});

	return res;
}

async function getPredictedStats() {
	const res = await api({
		method: "get",
		url: "/api/stats/predicted",
	});

	return res;
}

async function getReferencesStats(active) {
	let query = "";

	if (active) query += `${query ? "&" : "?"}active=${active}`;

	const res = await api({
		method: "get",
		url: `/api/stats/references${query}`,
	});

	return res;
}

async function getLeadStats(types = [], recruiters = [], sources = []) {
	let query = "";

	for (const type of types) {
		query += `${query ? "&" : "?"}personal=${type}`;
	}

	for (const recruiter of recruiters) {
		query += `${query ? "&" : "?"}recruiter=${recruiter}`;
	}

	for (const source of sources) {
		query += `${query ? "&" : "?"}source=${source}`;
	}

	const res = await api({
		method: "get",
		url: `/api/stats/leads${query}`,
	});

	return res;
}

async function getLeadTotals(type, types = [], recruiters = [], sources = []) {
	let query = "";

	for (const type of types) {
		query += `${query ? "&" : "?"}personal=${type}`;
	}

	for (const recruiter of recruiters) {
		query += `${query ? "&" : "?"}recruiter=${recruiter}`;
	}

	for (const source of sources) {
		query += `${query ? "&" : "?"}source=${source}`;
	}

	const res = await api({
		method: "get",
		url: `/api/stats/leads/totals/${type}${query}`,
	});

	return res;
}

async function getDetailedLeadStats(
	type,
	id,
	stage,
	page,
	perPage,
	sortBy,
	sortDesc,
	types = [],
	recruiters = [],
	sources = [],
) {
	let query = "";
	query += page ? `?page=${page}` : "";
	query += perPage ? `${query ? "&" : "?"}perPage=${perPage}` : "";
	query += sortBy ? `${query ? "&" : "?"}sortBy=${sortBy}` : "";
	query += sortDesc ? `${query ? "&" : "?"}sortDesc=${sortDesc}` : "";

	for (const type of types) {
		query += `${query ? "&" : "?"}personal=${type}`;
	}

	for (const recruiter of recruiters) {
		query += `${query ? "&" : "?"}recruiter=${recruiter}`;
	}

	for (const source of sources) {
		query += `${query ? "&" : "?"}source=${source}`;
	}

	const res = await api({
		method: "get",
		url: `/api/stats/leads/detailed/${type}/${id}/${stage}${query}`,
	});

	return res;
}

async function getUserStats(roles) {
	let query = "";

	for (const role of roles) {
		query += `${query ? "&" : "?"}role=${role}`;
	}

	const res = await api({
		method: "get",
		url: `/api/stats/users${query}`,
	});

	return res;
}

export {
	getGroupedStats,
	getPredictedStats,
	getReferencesStats,
	getLeadStats,
	getLeadTotals,
	getDetailedLeadStats,
	getUserStats,
};
