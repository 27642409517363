<style>
.vue-resizable-handle {
	filter: invert(100%);
	margin: 3px;
}
</style>
<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<center style="margin-top: 10px">
			<div v-if="placeholder" style="font-size: 1.25em">
				Ainda não tem widgets no seu dashboard
				<br />
				Adicione alguns no formulário abaixo
			</div>
			<div v-else>
				<div style="display: flex; gap: 10px; justify-content: center; align-items: center">
					<SlickList
						v-if="groupsOrderable"
						axis="x"
						:distance="10"
						v-model="groupsLocal"
						style="border: 1px solid #555; width: min-content; display: flex"
					>
						<SlickItem v-for="(group, i) in groupsLocal" :key="group.name" :index="i" style="z-index: 1000">
							<v-card style="width: 150px; background-color: #333; margin: 5px; padding: 10px; cursor: pointer">
								{{ group.name }}
							</v-card>
						</SlickItem>
					</SlickList>
					<v-btn-toggle v-else v-model="tab" mandatory>
						<v-btn v-for="group in groupsLocal" :key="group.pos" :value="group.pos">{{ group.name }}</v-btn>
					</v-btn-toggle>
					<v-btn icon @click="groupsOrderable ? updateGroupPosition() : (groupsOrderable = !groupsOrderable)">
						<v-icon>{{ groupsOrderable ? "mdi-content-save" : "mdi-sort-variant" }}</v-icon>
					</v-btn>
				</div>
				<grid-layout
					:layout="layout"
					:col-num="12"
					:row-height="30"
					:is-draggable="true"
					:is-resizable="true"
					:vertical-compact="true"
					:margin="[10, 10]"
					:use-css-transforms="true"
					style="max-width: 1000px"
					@layout-updated="layoutUpdatedEvent"
				>
					<grid-item
						v-for="item in layout"
						:x="item.x"
						:y="item.y"
						:w="item.w"
						:h="item.h"
						:minH="3"
						:maxH="3"
						:i="item.i"
						:key="item.i"
					>
						<v-card style="background-color: #333; width: 100%; height: 100%; padding: 10px">
							<v-card-text style="text-align: left; padding: 0px">
								<div style="display: flex; justify-content: space-between; align-items: center">
									{{ types.find(t => t.value === item.type)?.name }}
									{{
										item.type === "officeStats"
											? `(${views.find(s => s.value === (item.info.view || "cards"))?.name} | ${
													stats.find(s => s.value === (item.info.stat || "billing"))?.name
											  })`
											: ""
									}}
									<v-btn icon @click="() => handleDeleteWidget(item._id)"><v-icon>mdi-delete</v-icon></v-btn>
								</div>
								<v-row style="max-width: 400px; margin-bottom: 10px">
									<v-col>
										<v-autocomplete
											outlined
											dense
											v-model="item.group"
											:items="groups"
											item-text="name"
											item-value="value"
											label="Grupo"
											:rules="[required]"
											hide-details
											return-object
										/>
									</v-col>
									<v-col>
										<v-btn
											v-if="['officeStats', 'individualYearlyTable'].includes(item.type)"
											icon
											@click="() => openInfoDialog(item)"
										>
											<v-icon>mdi-open-in-new</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</grid-item>
				</grid-layout>
				<v-btn @click="handleEditWidget">Guardar</v-btn>
			</div>
			<v-dialog v-model="showInfoModal" width="400px">
				<v-sheet v-if="selectedWidget && selectedWidget.type === 'officeStats'" style="padding: 20px">
					<v-select
						v-if="selectedWidget.info.view === 'table'"
						dense
						outlined
						v-model="selectedWidget.info.stat"
						:items="stats"
						item-text="name"
						item-value="value"
						label="Estatística"
						:rules="[required]"
					/>
					<v-text-field
						v-if="selectedWidget.info.view === 'table'"
						dense
						outlined
						v-model="selectedWidget.info.header"
						label="Título"
					/>
					<v-autocomplete
						dense
						outlined
						label="Campos"
						:items="officeStatsFields.filter(f => !f.views || f.views.includes(selectedWidget.info.view))"
						item-text="name"
						item-value="value"
						hide-details
						@change="value => addField(value)"
						style="margin-bottom: 5px"
					/>
					<SlickList
						axis="y"
						v-model="selectedWidget.info.fields"
						:distance="10"
						@input="value => updateFieldsOrder(value)"
						style="border: 1px solid #555; min-height: 100px"
					>
						<SlickItem v-for="(field, i) in selectedWidget.info.fields" :key="i" :index="i" style="z-index: 1000">
							<v-card
								style="
									background-color: #555;
									margin: 5px;
									padding: 10px;
									cursor: pointer;
									display: flex;
									justify-content: space-between;
								"
							>
								{{ officeStatsFields.find(f => f.value === field)?.name }}
								<v-btn icon x-small @click="() => deleteField(i)">
									<v-icon small>mdi-delete</v-icon>
								</v-btn>
							</v-card>
						</SlickItem>
					</SlickList>
					<v-checkbox v-model="selectedWidget.info.includeChart" label="Mostrar gráfico" />
				</v-sheet>
				<v-sheet
					v-else-if="selectedWidget && selectedWidget.type === 'individualYearlyTable'"
					style="padding: 20px"
				>
					<v-autocomplete
						dense
						outlined
						label="Campos"
						:items="individualYearlyTableFields"
						item-text="name"
						item-value="value"
						hide-details
						@change="value => addField(value)"
						style="margin-bottom: 5px"
					/>
					<SlickList
						axis="y"
						v-model="selectedWidget.info.fields"
						:distance="10"
						@input="value => updateFieldsOrder(value)"
						style="border: 1px solid #555; min-height: 100px"
					>
						<SlickItem v-for="(field, i) in selectedWidget.info.fields" :key="i" :index="i" style="z-index: 1000">
							<v-card
								style="
									background-color: #555;
									margin: 5px;
									padding: 10px;
									cursor: pointer;
									display: flex;
									justify-content: space-between;
								"
							>
								{{ individualYearlyTableFields.find(f => f.value === field)?.name }}
								<v-btn icon x-small @click="() => deleteField(i)">
									<v-icon small>mdi-delete</v-icon>
								</v-btn>
							</v-card>
						</SlickItem>
					</SlickList>
				</v-sheet>
			</v-dialog>
			<v-form
				ref="form"
				@submit.prevent="handleAddWidget"
				style="border: 1px solid grey; border-radius: 4px; width: 400px; margin: 20px; padding: 20px"
			>
				<h3>Adicionar Widget</h3>
				<br />
				<v-combobox
					outlined
					v-model="group"
					:items="groups"
					item-text="name"
					item-value="value"
					label="Grupo"
					:rules="[required]"
				/>
				<v-select
					outlined
					v-model="type"
					:items="types"
					item-text="name"
					item-value="value"
					label="Tipo"
					:rules="[required]"
				/>
				<div v-if="type === 'officeStats'">
					<v-select
						dense
						outlined
						v-model="view"
						:items="views"
						item-text="name"
						item-value="value"
						label="Visualização"
						:rules="[required]"
					/>
					<v-select
						v-if="view === 'table'"
						dense
						outlined
						v-model="stat"
						:items="stats"
						item-text="name"
						item-value="value"
						label="Estatística"
						:rules="[required]"
					/>
					<v-text-field
						v-if="selectedWidget.info.view === 'table'"
						dense
						outlined
						v-model="header"
						label="Título"
					/>
					<v-autocomplete
						dense
						outlined
						label="Campos"
						:items="officeStatsFields.filter(f => !f.views || f.views.includes(view))"
						item-text="name"
						item-value="value"
						hide-details
						@change="value => addField(value, true)"
						style="margin-bottom: 5px"
						:disabled="!view"
					/>
					<SlickList
						axis="y"
						v-model="fields"
						:distance="10"
						@input="value => updateFieldsOrder(value, true)"
						style="border: 1px solid #555; min-height: 100px"
					>
						<SlickItem v-for="(field, i) in fields" :key="i" :index="i" style="z-index: 1000">
							<v-card
								style="
									background-color: #555;
									margin: 5px;
									padding: 10px;
									cursor: pointer;
									display: flex;
									justify-content: space-between;
								"
							>
								{{ officeStatsFields.find(f => f.value === field)?.name }}
								<v-btn icon x-small @click="() => deleteField(i, true)">
									<v-icon small>mdi-delete</v-icon>
								</v-btn>
							</v-card>
						</SlickItem>
					</SlickList>
					<v-checkbox v-model="includeChart" label="Mostrar gráfico" />
				</div>
				<v-btn @click="handleAddWidget">Adicionar</v-btn>
			</v-form>
		</center>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { GridLayout, GridItem } from "vue-grid-layout";
import { SlickList, SlickItem } from "vue-slicksort";

import { addWidget, editWidgets, deleteWidget } from "../../api/widgets";

export default Vue.extend({
	name: "WidgetsForm",
	props: ["groups", "widgets", "onClose"],
	components: { GridLayout, GridItem, SlickList, SlickItem },
	created() {
		this.groupsLocal = this.groups;

		this.updateLayout();
	},
	watch: {
		groups: function (value) {
			this.groupsLocal = value;
		},
		widgets: function () {
			this.updateLayout();
		},
		tab: function () {
			this.updateLayout();
		},
		group: function () {
			if (!this.group.name) {
				this.group = { name: this.group, pos: this.groups.length };
			}
		},
		view: function () {
			this.fields = [];
		},
		stat: function () {
			if (!this.header) this.header = this.stats.find(s => s.value === this.stat).name;
		},
	},
	data() {
		return {
			loading: false,

			required: value => !!value || value === 0 || "Obrigatório",

			types: [
				{ name: "Estatísticas das Agências", value: "officeStats" },
				{ name: "Visão Geral das Estatísticas", value: "statsOverview" },
				{ name: "Gráfico de Comparações", value: "comparisonBarChart" },
				{ name: "Gráfico de Previsões", value: "predictedBarChart" },
				{ name: "Tabela das Estatísticas", value: "individualYearlyTable" },
				{ name: "Referências", value: "references" },
				{ name: "Estatísticas de Cargos", value: "roleCards" },
				{ name: "Estatísticas de Géneros", value: "genderPieChart" },
				{ name: "Estatísticas de Agências", value: "officePieChart" },
				{ name: "Estatísticas de Anos de Empresa", value: "companyAgeBarChart" },
				{ name: "Estatísticas de Idades", value: "ageBarChart" },
				{ name: "Estatísticas de Países", value: "countryCards" },
				{ name: "Totais das Leads", value: "leadsGoalCards" },
				{ name: "Ranking das Leads", value: "leadsRanking" },
				{ name: "Gráfico de Linhas das Leads", value: "leadsLineChart" },
				{ name: "Funil das Leads", value: "leadsFunnelChart" },
				{ name: "Sessões", value: "sessions" },
			],

			views: [
				{ name: "Cartões", value: "cards" },
				{ name: "Tabela", value: "table" },
			],
			stats: [
				{ name: "Faturação", value: "billing" },
				{ name: "Previsto", value: "predicted" },
				{ name: "Em Pipeline", value: "pipeline" },
				{ name: "Pipeline", value: "reservations" },
				{ name: "Angariações", value: "findings" },
			],
			officeStatsFields: [
				{ name: "Rank", value: "rank", views: ["table"] },
				{ name: "Nome", value: "name", views: ["table"] },
				{ name: "Mensal", value: "monthly" },
				{ name: "Anual", value: "yearly" },
				{ name: "Em Pipeline", value: "pipeline" },
				{ name: "Previsto (Mensal)", value: "monthlyPredicted" },
				{ name: "Pago (Mensal)", value: "monthlyBillingPaid" },
				{ name: "Previsto (Anual)", value: "yearlyPredicted" },
				{ name: "Pago (Anual)", value: "yearlyBillingPaid" },
				{ name: "Objetivo (Mensal)", value: "monthlyGoal" },
				{ name: "% do Objetivo (Mensal)", value: "monthlyGoalPercent", views: ["table"] },
				{ name: "Diferença do Objetivo (Mensal)", value: "monthlyComparedToGoal", views: ["table"] },
				{ name: "Diferença do Objetivo % (Mensal)", value: "monthlyComparedToGoalPercent", views: ["table"] },
				{ name: "Esperado (Mensal)", value: "monthlyExpected", views: ["table"] },
				{ name: "Diferença do Esperado (Mensal)", value: "monthlyComparedToExpected", views: ["table"] },
				{ name: "Diferença do Esperado % (Mensal)", value: "monthlyComparedToExpectedPercent", views: ["table"] },
				{ name: "Objetivo (Anual)", value: "yearlyGoal" },
				{ name: "% do Objetivo (Anual)", value: "yearlyGoalPercent", views: ["table"] },
				{ name: "Diferença do Objetivo (Anual)", value: "yearlyComparedToGoal", views: ["table"] },
				{ name: "Diferença do Objetivo % (Anual)", value: "yearlyComparedToGoalPercent", views: ["table"] },
				{ name: "Esperado (Anual)", value: "yearlyExpected", views: ["table"] },
				{ name: "Diferença do Esperado (Anual)", value: "yearlyComparedToExpected", views: ["table"] },
				{ name: "Diferença do Esperado % (Anual)", value: "yearlyComparedToExpectedPercent", views: ["table"] },
				{ name: "Comparado ao mesmo mês do ano anterior", value: "yearlyGrowth", views: ["table"] },
				{ name: "Comparado ao mês anterior", value: "monthlyPeriodicGrowth", views: ["table"] },
				{ name: "Comparado à mediana (Mensal)", value: "monthlyComparedToMedian", views: ["table"] },
				{ name: "Comparado à média (Mensal)", value: "monthlyComparedToAverage", views: ["table"] },
				{ name: "Percentagem do total (Mensal)", value: "monthlyPercentOfTotal", views: ["table"] },
				{ name: "Comparado ao ano anterior", value: "yearlyPeriodicGrowth", views: ["table"] },
				{ name: "Comparado à mediana (Anual)", value: "yearlyComparedToMedian", views: ["table"] },
				{ name: "Comparado à média (Anual)", value: "yearlyComparedToAverage", views: ["table"] },
				{ name: "Percentagem do total (Anual)", value: "yearlyPercentOfTotal", views: ["table"] },
				{ name: "Fatura mais alta (Mensal)", value: "monthlyHighest", views: ["table"] },
				{ name: "Fatura mais baixa (Mensal)", value: "monthlyLowest", views: ["table"] },
				{ name: "Nº de Faturas (Mensal)", value: "monthlyCount", views: ["table"] },
				{ name: "Fatura média (Mensal)", value: "monthlyAverage", views: ["table"] },
				{ name: "Fatura mediana (Mensal)", value: "monthlyMedian", views: ["table"] },
				{ name: "Última Fatura (Mensal)", value: "monthlyLastDate", views: ["table"] },
				{ name: "Fatura mais alta (Anual)", value: "yearlyHighest", views: ["table"] },
				{ name: "Fatura mais baixa (Anual)", value: "yearlyLowest", views: ["table"] },
				{ name: "Nº de Faturas (Anual)", value: "yearlyCount", views: ["table"] },
				{ name: "Fatura média (Anual)", value: "yearlyAverage", views: ["table"] },
				{ name: "Fatura mediana (Anual)", value: "yearlyMedian", views: ["table"] },
				{ name: "Última Fatura (Anual)", value: "yearlyLastDate", views: ["table"] },

				{ name: "Texto do Mês", value: "monthlyLabel", views: ["cards"] },
				{ name: "Pipeline (Mensal)", value: "monthlyReservations", views: ["cards"] },
				{ name: "Angariações (Mensal)", value: "monthlyFindings", views: ["cards"] },
				{ name: "Divisor", value: "divider", views: ["cards"] },
				{ name: "Texto do Ano", value: "yearlyLabel", views: ["cards"] },
				{ name: "Pipeline (Anual)", value: "yearlyReservations", views: ["cards"] },
				{ name: "Angariações (Anual)", value: "yearlyFindings", views: ["cards"] },
				{ name: "Texto do Em Pipeline", value: "pipelineLabel", views: ["cards"] },
				{ name: "Objetivo do Pipeline", value: "pipelineGoal", views: ["cards"] },
			],
			individualYearlyTableFields: [
				{ name: "Grupos", value: "groups", disabled: true },
				{ name: "Consultores", value: "consultant" },
				{ name: "Diretores Comerciais", value: "commercialDirector" },
				{ name: "Agências", value: "office" },
				{ name: "Estatísticas", value: "stats", disabled: true },
				{ name: "Faturação", value: "billing" },
				{ name: "Previsto", value: "predicted" },
				{ name: "Pipeline", value: "reservations" },
				{ name: "Desistências", value: "giveUps" },
				{ name: "Angariações", value: "findings" },
				{ name: "Visualizações", value: "views", disabled: true },
				{ name: "Por mês", value: "months" },
				{ name: "Comparação por período", value: "comparison" },
				{ name: "Objetivos", value: "goals" },
				{ name: "Estatísticas Comparativas", value: "comparativeStats" },
				{ name: "Mapa de Produtividade", value: "productivity" },
			],

			placeholder: false,
			tab: 0,
			layout: [],
			groupsLocal: [],
			groupsOrderable: false,

			showInfoModal: false,
			selectedWidget: null,

			type: null,
			group: null,
			view: null,
			stat: null,
			header: null,
			fields: [],
			includeChart: true,
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		updateLayout() {
			const widgets = this.widgets.filter(w => w.group.pos === this.tab);

			const layout = [];
			let i = 0;
			this.placeholder = false;
			for (const widget of widgets) {
				if (!widget._id) this.placeholder = true;

				layout.push({
					x: widget.x,
					y: widget.y,
					w: widget.width,
					h: 3,
					i,
					_id: widget._id,
					group: widget.group,
					type: widget.type,
					info: widget.info,
				});
				i++;
			}

			this.layout = layout;

			this.group = this.groups[this.tab];
		},
		async layoutUpdatedEvent(layout) {
			for (const widget of layout) {
				widget.width = widget.w;
			}
		},
		async updateGroupPosition() {
			this.loading = true;

			for (const widget of this.widgets) {
				const groupIndex = this.groupsLocal.findIndex(g => g.name === widget.group.name);

				widget.group.pos = groupIndex;
			}

			const response = await editWidgets(this.widgets);

			if (response.status === 200) {
				this.onClose();
			}

			this.loading = false;

			this.groupsOrderable = false;
		},
		openInfoDialog(item) {
			this.selectedWidget = item;

			this.showInfoModal = !this.showInfoModal;
		},
		addField(field, add) {
			if (add) this.fields.push(field);
			else this.selectedWidget.info.fields.push(field);
		},
		deleteField(index, add) {
			if (add) this.fields.splice(index, 1);
			else this.selectedWidget.info.fields.splice(index, 1);
		},
		updateFieldsOrder(value, add) {
			if (add) this.fields = value;
			else this.selectedWidget.info.fields = value;
		},
		isFormValid() {
			if (!this.$refs.form.validate()) return false;

			return true;
		},
		async handleAddWidget() {
			this.loading = true;

			const { type, group, view, stat, header, fields, includeChart } = this;

			if (!this.isFormValid()) return;

			const response = await addWidget({
				type,
				group,
				x: 0,
				y: 0,
				width: 12,
				info: { view, stat, header, fields, includeChart },
			});

			if (response.status === 201) {
				this.openToast({ message: "Widget adicionado com sucesso", color: "green" });

				this.onClose();
			}

			this.loading = false;
		},
		async handleEditWidget() {
			this.loading = true;

			const response = await editWidgets(this.layout);

			if (response.status === 200) {
				this.openToast({ message: "Widgets atualizados com sucesso", color: "green" });

				this.onClose();
			}

			this.loading = false;
		},
		async handleDeleteWidget(id) {
			this.loading = true;

			const response = await deleteWidget(id);

			if (response.status === 200) {
				this.openToast({ message: "Widget apagado com sucesso", color: "green" });

				this.onClose();
			}

			this.loading = false;
		},
	},
});
</script>
